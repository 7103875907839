.mod-links {
	&.element-template--menu, &.element-template--inline {
		ul {
			@include clear-ul;
		}
	}

    &.element-template--six-tiles {
        ul {
            @include clear-ul;
            @include basic-columns (
				$selector: 'li',
				$count: 6,
				$gutter: ( $red-margin, 2.6% ),
				$gutter-bottom: $purple-margin
            );
        }

        li {
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            padding: 3px;
			//border: 1px solid $border-shadow-color;`
            box-shadow: 0px 0px 15px 1px rgba( $border-shadow-color, .15 );
            text-align: center;

            &:hover {
                .wrapper img {
                    opacity: 1;
                    transform: scale(1.04) rotate(.001deg);
                    backface-visibility: hidden;
                }

                h6.link-subtext {
                    &:after {
                        animation: beating .3s ease-in-out infinite alternate;
                        backface-visibility: hidden;
                    }
                }
            }
        }

        a {
            display: block;
            text-decoration: none;
            color: $theme-primary-color;
        }

        .wrapper {
            position: relative;
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                height: auto;
                transform-origin: center;
                opacity: .98;
                transform: scale(1) rotate( 0deg);
                transition: transform .3s ease-in-out, opacity .3s ease-in-out;
                backface-visibility: hidden;
            }
        }

        h6.link-subtext {
            display: block;
            margin-top: 1em;
            margin-bottom: .5em;
            color: $theme-primary-color;

			&:after {
				display: block;
				content: '';
				box-sizing: border-box;
				background: {
					image: url('../../images/read-more.svg');
					repeat: no-repeat;
					size: 30px 30px;
					position: center bottom;
				}
                width: 30px;
                height: 30px;
                margin: .5em auto 1em auto;
			}
        }
    }

    &.element-template--image-label {
        ul {
            @include clear-ul;
            @include basic-columns (
                $selector: 'li',
                $count: 4,
                $gutter: ( $red-margin, 2.6% ),
                $gutter-bottom: $purple-margin,
                $breakpoint: tablet-portrait
            );

            @include tablet-portrait {
                @include basic-columns (
                    $selector: 'li',
                    $count: 3,
                    $gutter: ( $red-margin, 2.6% ),
                    $gutter-bottom: $purple-margin,
                    $breakpoint: phone-landscape
                );
            }

            @include phone-landscape {
                @include basic-columns (
                    $selector: 'li',
                    $count: 2,
                    $gutter: ( $red-margin, 2.6% ),
                    $gutter-bottom: $purple-margin,
                    $breakpoint: phone-portrait
                );
            }
        }

        li {
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            border-radius: 3px;

            &:hover img {
                opacity: 1;
                transform: scale(1.04) rotate(.001deg);
                backface-visibility: hidden;
            }

            &:hover .subtext {
                background: $image-label-background-hover-color;
            }
        }

        a {
            display: block;

            &[href="#"] {
                pointer-events: none;
            }
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
            border-radius: 3px;
            opacity: .95;
            transition: opacity .3s ease-in-out, transform .3s ease-in-out;
            box-shadow: inset 0 0 1px #1d1d1d;
            transform: scale( 1 ) rotate( 0deg );
            backface-visibility: hidden;
        }

        .subtext {
            position: absolute;
            box-sizing: border-box;
            top: 50%;
            left: 0;
            background: $image-label-background-color;
            color: $image-label-color;
            padding: $purple-margin/2 $purple-margin;
            transform: translateY( -50% );
            transition: background .3s ease-in-out;
            border-radius: 0 3px 3px 0;
            backface-visibility: hidden;
        }
    }

	&.element-template--logos {
        ul {
            @include clear-ul;
            @include basic-columns (
                $selector: 'li',
                $count: 6,
                $gutter: ( $red-margin, 2.6% ),
                $gutter-bottom: $purple-margin
            )
        }

        li {
            box-sizing: border-box;
            position: relative;
            display: table;
            table-layout: fixed;
            width: 100%;
            box-shadow: 0 0 15px 1px rgba( $border-shadow-color, .15 );
            border-radius: 3px;

            a {
                box-sizing: border-box;
                display: table-cell;
                vertical-align: middle;
                padding: 2.5rem;

                &[href="#"] {
                    pointer-events: none;
                }

                &:hover img {
                    transform: scale( 1.15 ) rotate(.001deg);
                    opacity: 1;
                }
            }

            img {
                display: block;
                width: 100%;
                height: auto;
                margin: 0 auto;
                opacity: .95;
                transform: scale( 1 ) rotate( 0deg );
                transform-origin: center;
                transition: transform .3s, opacity .3s;
                backface-visibility: hidden;
            }
        }
	}

	&.element-template--default, &.element-template--default-small {
		ul {
			@include clear-ul;
			margin: 0;
			padding: 0;
		}

		li {
            position: relative;
            box-sizing: border-box;
            display: table;
            width: 100%;
            table-layout: fixed;
            //border: 1px solid $border-shadow-color;
            box-shadow: 0px 0px 15px 1px rgba( $border-shadow-color, .15 );
            border-radius: 3px;
            overflow: hidden;

			a {
                position: relative;
				box-sizing: border-box;
				display: table-row;
				line-height: 1.3;
				text-decoration: none;
                color: $theme-primary-color;
			}

            .link-image, h6.link-subtext {
                position: relative;
                box-sizing: border-box;
                vertical-align: middle;
                display: table-cell;
            }

            .link-image {
                width: 130px;
                overflow: hidden;
            }

			img {
				display: block;
                width: 105%;
                height: auto;
                opacity: .95;
                transform: scale( 1 ) rotate( 0deg );
                transform-origin: center;
                transition: transform .3s ease-in-out, opacity .3s ease-in-out;
                backface-visibility: hidden;
			}

            h6 {
                width: calc(100% - 100px);
                padding-right: calc(1em + 30px);
                padding-left: 1em;
                line-height: 30px;

                &:after {
                    content: '';
                    display: block;
                    vertical-align: middle;
                    position: absolute;
                    top: calc( 50% - 15px );
                    right: 15px;
                    background: {
                        image: url('../../images/read-more.svg');
                        repeat: no-repeat;
                        size: 30px 30px;
                        position: right center;
                    };
                    width: 30px;
                    height: 30px;
                }
            }

            &:hover {
                a {}

                .link-image img {
                    transform: scale( 1.05 ) rotate(.001deg);
                    opacity: 1;
                }

                h6:after {
                    animation: beating .3s ease-in-out infinite alternate;
                }
            }

			//&.type-image {
			//	min-width: 10em;
            //
			//	a {
			//		display: block;
			//		padding: 0;
            //
			//		&:before, &:after {
			//			content: normal;
			//		}
			//	}
            //
			//	img {
			//		margin: 0;
			//		border-radius: 0;
			//		position: absolute;
			//		left: 50%;
			//		max-height: none;
			//		max-width: none;
			//		width: 100%;
			//		top: 50%;
			//		transform: translateY(-50%) translateX(-50%);
			//	}
			//}
		}
	}

	&.element-template--default {
		ul {
			@include basic-columns(3, $breakpoint: phone-landscape, $selector: 'li');
		}
	}

	&.element-template--default-small {
		li {
			display: inline-block;
			margin-right: .6em;

			a {
				height: 2.5em;
				padding-left: .8em;

				&:before, &:after {
					font-size: .7em;
				}
			}

			img {
				max-height: 2.5em;
				max-width: 2.5em;
			}

			&.type-both {
				img {
					margin-left: -1em;
					margin-right: .6em;
				}
			}

			&.type-image {
				min-width: 8em;
			}
		}
	}

	&.element-template--menu {
		border: 1px solid #e3e3e3;
		border-radius: 5px;
		overflow: hidden;

		li, a {
			display: block;
		}

		li {
			border-bottom: 1px solid #e3e3e3;

			&:last-child {
				border-bottom: none;
			}
		}

		a {
			color: inherit;
			padding: .8em 1em;
			text-decoration: none;
			transition: background .18s;
            backface-visibility: hidden;

			&:hover {
				background: rgba(#000, .03);
			}
		}

		.active a {
			color: $theme-primary-color;
		}

		.boxed & {
			border: none;
			border-radius: 0;
			margin: -1.5em;

			&:not(:first-child) {
				margin-top: $element-vertical-spacing;
			}

			&:not(:last-child) {
				margin-bottom: $element-vertical-spacing;
			}

			a {
				padding: .8em 1.5em;
			}
		}
	}

	&.element-template--inline {
		ul {
			margin: -.3em 0;
		}

		li {
			display: inline-block;
			margin-left: -.32em;
			position: relative;

			&:first-child {
				margin-left: 0;
			}

			&:before, &:after {
				background: #d3d3d3;
				content: '' !important;
				display: block;
				font-size: 1.14rem;
				height: 1.2em;
				position: absolute;
				top: .4em;
				left: 0;
				width: 1px;
			}

			&:after {
				display: none;
				left: auto;
				right: 0;
			}

			&:last-child:after {
				display: block;
			}

			a {
				display: inline-block;
				padding: .3em .6em;
			}

			&.active {
				z-index: 1;

				&:before, &:after {
					display: none;
				}

				a {
					background: $theme-primary-color;
					border-radius: 3px;
					color: set-color-for-background-color($theme-primary-color);
				}
			}

			@include breakpoint(phone-portrait) {
				border: 1px solid #d3d3d3;
				border-width: 1px 0;
				display: block;
				margin: -1px 0 0;

				&:first-child {
					margin-top: 0;
				}

				a {
					display: block;
					padding: .5em 1em;
				}

				&:before, &:after {
					display: none !important;
				}
			}
		}
	}
}