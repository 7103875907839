/*
 *  Owl Carousel - Animate Plugin
 */
@keyframes slider--fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.element-template--slider,
.element-template--slider-small,
.element-template--slider-big,
.element-template--slider-huge {
    background: rgba(#666, .8);
    position: relative;

    .full-width & {
        border-bottom: 5px solid $theme-primary-color;
    }

    .swiper-container {
        position: relative;
        height: 26.316em;
        width: auto;
    }

    .swiper-wrapper {
        position: relative;
        box-sizing: border-box;
    }

    .swiper-slide {
        box-sizing: border-box;
        background: {
            position: center;
            size: cover;
            repeat: no-repeat;
        };
        top: 0; bottom: 0;
        right: 0; left: 0;
        width: 100%;
        display: block;

        .text-wrapper {
            position: relative;
            text-align: center;
            top: 50%;
            transform: translateY( -50% );
        }

        .text {
            background: $region-cta-background-color;
            border-radius: 5px;
            color: $region-cta-text-color;
            display: inline-block;
            font-size: 1.84em;
            font-weight: 700;
            height: auto;
            padding: .44em .8em;

            h1, h2, h3, h4, h5, h6 {
                color: $region-cta-head-color;
            }

            a {
                color: $region-cta-link-color;
            }
        }
    }

    .swiper-nav-button:nth-child( 1n ) {
        box-sizing: border-box;
        width: 44px;
        height: 44px;
        background: {
            repeat: no-repeat;
            size: contain;
            position: center;
        };
        &.swiper-button-prev {
            background-image: url('../../images/slider/prev.svg');
        }

        &.swiper-button-next {
            background-image: url('../../images/slider/next.svg');
        }
    }

    // MOD DETAIL
    .swiper-container.detail {
        height: inherit;
        width: auto;

        .swiper-wrapper {
            .swiper-slide {
                .text-wrapper {
                    box-sizing: border-box;
                    transform: inherit;
                    top: inherit;
                    text-align: left;

                    .text {
                        font-size: 25px;
                        font-weight: normal;
                        box-sizing: border-box;
                        position: relative;
                        padding: 2em 1.7em;
                        margin-top: 6rem;
                        margin-bottom: 6rem;
                    }
                }
            }
        }
    }
    &:after {
        position:absolute;
        z-index:1;
        content:"";
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color:rgba(16, 65, 87, 0.8);
    }
}

.element-template--slider-small .swiper-container {
    height: 8.316em;
}
.element-template--slider-big .swiper-container {
    height: 31.316em;
}
.element-template--slider-huge .swiper-container {
    height: 36.316em;
}