// if google font
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

// MARGES
$blue-margin: 10px;
$brown-margin: 15px;
$purple-margin: 20px;
$red-margin: 30px;
$yellow-margin: 35px;
$orange-margin: 40px;
$pink-margin: 50px;
$magenta-margin: 70px;
$green-margin: 80px;
$light-blue: 130px;

$theme-primary-color: #104157;
$theme-secondary-color: #fce108;
$link-color: $theme-primary-color;
$button-background: transparent;

$column-gutter: 2.4em;
$eresults-branding: true;
$font-color: #222222;
$font-family: 'Open Sans', sans-serif;
$font-size: 1rem;
$font-weight: 500;

$header-font-color: #104157;
$header-font-family: null;
$header-font-weight: 300;

$h1-font-size: 2.222em;
$h1-font-color: null;
$h1-font-weight: 300;
$h1-margin-bottom: null;

$h2-font-size: 1.944em;
$h2-font-color: null;
$h2-font-weight: 300;
$h2-margin-bottom: null;

$h3-font-size: 1.667em;
$h3-font-color: null;
$h3-font-weight: 300;
$h3-margin-bottom: null;

$h4-font-size: 25px;
$h4-font-color: null;
$h4-font-weight: 300;
$h4-margin-bottom: null;

$h5-font-size: 1.389em;
$h5-font-color: null;
$h5-font-weight: 300;
$h5-margin-bottom: null;

$h6-font-size: 1.111em;
$h6-font-color: null;
$h6-font-weight: 300;
$h6-margin-bottom: null;

$image-label-background-color: rgba( $theme-secondary-color, .85 );
$image-label-background-hover-color: rgba( $theme-secondary-color, .95 );
$image-label-color: #fff;

$fragment-dark-color: #f9f9f9;
$border-shadow-color: rgba( $theme-primary-color, .3 );

$fragment-dark-color: #f6f6f6;

$region-cta-background-color: rgba($theme-primary-color, .95);
$region-cta-head-color: #fff;
$region-cta-text-color: #fff;
$region-cta-link-color: #fff;

$footer-background-color: #0d3649;
$footer-link-color: $theme-secondary-color;

$footer-cta-background-color: $theme-secondary-color;
$footer-cta-link-color: $theme-secondary-color;

$footer-newsletter-background-color: $theme-primary-color;
$footer-newsletter-link-color: $footer-link-color;
$footer-header-font-color: $theme-secondary-color;

$logo-height: 1.5;
$menu-horizontal-spacing: 1.4em;
$menu-font-color: null;
$menu-font-weight: null;
$menu-active-font-color: #808184;
$menu-active-font-weight: null;

$fragment-spacing: 2em;

$list-ordered-icon-color: $theme-primary-color;
$list-unordered-icon-color: $theme-primary-color;
$list-unordered-checks-icon: url('../../images/check.svg');
$makers-mark-link-color: rgba( $font-color, .35 );
$makers-mark-link-hover-color: $font-color;